
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getInterestBoardMenuUidList, getInterestPostList } from '@/api/myHomepageInterest';
import { IInterestCompany, IMyHomepageDetail, IPost } from '@/types';
import BoardPagination from '@/components/board/pagination.vue';
import { getInterestCompanyList } from '@/api/interestCompany';
import { UserModule } from '@/store/user';

@Component({
  name: 'HomepageInterestPost',
  components: {
    BoardPagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  mounted() {
    this.getInterestBoardList();
    this.getInterestCompanyList();
    this.getPostList();
  }

  private listTotal = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 8,
    userUid: '',
  };

  private interestCompanyList: IInterestCompany[] = [];

  private postList: IPost[] = [];

  private interestBoardList: any[] = [];

  get isManager() {
    return UserModule.userId === this.detail.userDetail.userId;
  }

  private getInterestBoardList() {
    getInterestBoardMenuUidList().then((res) => {
      this.interestBoardList = res.data;
    });
  }

  private getInterestCompanyList() {
    getInterestCompanyList(this.$route.params.nickname).then((res) => {
      this.interestCompanyList = res.data;
    });
  }

  private getPostList() {
    getInterestPostList(this.$route.params.nickname, this.listQuery).then((res) => {
      this.postList = res.data.content;
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private getIndex(index: number) {
    let totalSize = (this.listQuery.page) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.listTotal - totalSize - index;
  }

  private handleChangePaging(page: number) {
    this.listQuery.page = page;
    this.getPostList();
  }

  private handleClickCompany(uid: string) {
    this.listQuery = {
      ...this.listQuery,
      page: 0,
      userUid: uid,
    };
    this.getPostList();
  }

  private getInterestPostRouter(interestPost: IPost) {
    const menuFindIndex = this.interestBoardList.findIndex((b) => b.boardUid === interestPost.boardUid);
    const interestMenuUid = this.interestBoardList[menuFindIndex].menuUid;
    return {
      name: 'PostDetail',
      params: {
        menuUid: interestMenuUid,
        postUid: interestPost.uid,
      },
    };
  }
}
