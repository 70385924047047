
import { IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepageInterestPost from '@/components/homepage/interest.vue';

@Component({
  name: 'HomepageInterest',
  components: {
    HomepageInterestPost,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail
}
